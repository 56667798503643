import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type Props = {
  excludedIds?: string[];
};

function getExcludeIdHeights(ids: string[]) {
  return ids.reduce((height, id) => height + (document.getElementById(id)?.offsetHeight || 0), 0);
}

export function ScrollProgress({ excludedIds = [] }: Props) {
  const [scrollPercentage, setScrollPercentage] = useState(0);

  useEffect(() => {
    const excludedHeight = getExcludeIdHeights(excludedIds);
    const pageHeight = document.documentElement.offsetHeight - window.innerHeight - excludedHeight;
    const handleScroll = () => setScrollPercentage((window.scrollY / pageHeight) * 100);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPercentage]);

  return (
    <Container>
      <Progress width={scrollPercentage} />
    </Container>
  );
}

const Container = styled.div`
  z-index: 50;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.1);
`;

const Progress = styled.div<{ width: number }>`
  position: absolute;
  height: 100%;
  width: ${({ width }) => `${width}%`};
  background-color: var(--color-primary);
`;
