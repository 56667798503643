import { DiscussionEmbed } from 'disqus-react';
import { graphql, Link } from 'gatsby';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { MinutesToRead } from '../components/MinutesToRead';
import { ScrollProgress } from '../components/ScrollProgress';
import { SEO } from '../components/SEO';

require('prismjs/themes/prism-solarizedlight.css');

type Props = {
  data: {
    contentfulBlogPost: {
      slug: string;
      title: string;
      tags: string;
      publishDate: string;
      updatedAt: string;
      author: {
        name: string;
      };
      description: {
        description: string;
      };
      body: {
        childMarkdownRemark: {
          html: string;
          wordCount: {
            words: number;
          };
        };
      };
    };
  };
};

export default function BlogPost(props: Props) {
  const data = props.data.contentfulBlogPost;
  const path = typeof window === `undefined` ? undefined : location.pathname;

  return (
    <Layout path={path}>
      <SEO
        title={data.title}
        description={data.description.description}
        meta={[{ name: 'keywords', content: data.tags }]}
      />
      <Container>
        <Back to="/blog">↩ Blogs</Back>
        <Title>{data.title}</Title>
        <SubTitle>
          {moment(data.publishDate).format('LL')}
          <MinutesToRead words={data.body.childMarkdownRemark.wordCount.words} />
        </SubTitle>
        <div
          dangerouslySetInnerHTML={{
            __html: data.body.childMarkdownRemark.html,
          }}
        />
        <DiscussionEmbed
          shortname="labithiotis"
          config={{
            title: data.title,
            identifier: data.slug,
            url: `/blog/${data.slug}`,
          }}
        />
      </Container>
      <ScrollProgress excludedIds={['disqus_thread']} />
    </Layout>
  );
}

const Back = styled(Link)`
  position: relative;
  top: -20px;
  font-size: 14px;
`;

const Title = styled.h1`
  margin: 0;
`;

const SubTitle = styled.div`
  color: var(--color-gray500);
  margin-bottom: 40px;
`;

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      tags
      publishDate
      updatedAt
      author {
        name
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
          wordCount {
            words
          }
        }
      }
    }
  }
`;
